// Toggle bio
const toggleBioLink = document.querySelector('.js-toggle-blog-bio')
const bio = document.querySelector('.js-blog-bio')

toggleBioLink?.addEventListener('click', (e) => {
  if (!(toggleBioLink instanceof HTMLAnchorElement)) {
    return
  }
  e.preventDefault()
  if (!bio) {
    console.error('No bio found')
  } else {
    bio.classList.toggle('hidden')
  }
  toggleBioLink.blur()
  toggleBioLink.textContent = toggleBioLink.textContent === 'Show bio' ? 'Hide bio' : 'Show bio'
})

// Open share modals
const shareLinks = document.getElementsByClassName('js-share-link')

for (const link of shareLinks) {
  if (!(link instanceof HTMLAnchorElement)) {
    continue
  }
  link.addEventListener('click', (e) => {
    e.preventDefault()
    const href = link.getAttribute('href')

    if (!href) {
      return
    }

    const { website } = link.dataset

    if (!website || (website !== 'facebook' && website !== 'twitter')) return

    const websites: Record<string, { width: number; height: number }> = {
      facebook: { width: 600, height: 300 },
      twitter: { width: 600, height: 254 },
    }

    const options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,'
    window.open(
      href,
      '',
      `${options}height=${websites[website].height},width=${websites[website].width}`,
    )
  })
}
