// This code repeats the code at _includes/static-userbar.html

import type { LoginData } from '~/features/workspaces/types'

// We can't reuse this file, - we need this code in es5 inside the <script/> tag, so that header is updated without delay.
const updateHeader = (loginData: Partial<LoginData>) => {
  const signUpMenuItem = document.getElementById('sign-up-menu-item')
  const signInMenuItem = document.getElementById('sign-in-menu-item')
  const consoleMenuItem = document.getElementById('console-menu-item')

  // These won't be present in CRM
  if (!signUpMenuItem || !signInMenuItem || !consoleMenuItem) {
    return
  }

  if (loginData.loggedIn) {
    signUpMenuItem.style.display = 'none'
    signInMenuItem.style.display = 'none'
    consoleMenuItem.style.display = 'block'

    return
  }

  signUpMenuItem.style.display = 'block'
  signInMenuItem.style.display = 'block'
  consoleMenuItem.style.display = 'none'
}

export default updateHeader
