import { docsearchConfig } from './config.ts'
import './static-site.scss' // our own style overrides

export async function initDocSearch() {
  const root = document.getElementById('tldocsearch-root')
  if (!root) return

  const { default: docsearch } = await import('@docsearch/js')

  // https://docsearch.algolia.com/docs/api
  docsearch({ container: root, ...docsearchConfig })
}
