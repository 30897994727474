const filePreviewDemoArea = document.getElementById('file-preview-demo')

if (filePreviewDemoArea !== null) {
  import('./file-preview-demo/render.tsx')
    .then(({ default: render }) => {
      console.log('test')
      render(filePreviewDemoArea)
    })
    .catch((filePreviewTransformError) => {
      console.error(filePreviewTransformError)
      if (filePreviewTransformError instanceof Error) {
        filePreviewDemoArea.innerHTML =
          filePreviewTransformError.stack ?? filePreviewTransformError.message
      } else {
        filePreviewDemoArea.innerHTML = filePreviewTransformError
      }
    })
}
