const websitePreviewDemoArea = document.getElementById('website-preview-demo')

if (websitePreviewDemoArea !== null) {
  import('./website-preview-demo/render.tsx')
    .then(({ default: render }) => {
      console.log('test')
      render(websitePreviewDemoArea)
    })
    .catch((websitePreviewTransformError) => {
      console.error(websitePreviewTransformError)
      if (websitePreviewTransformError instanceof Error) {
        websitePreviewDemoArea.innerHTML =
          websitePreviewTransformError.stack ?? websitePreviewTransformError.message
      } else {
        websitePreviewDemoArea.innerHTML = websitePreviewTransformError
      }
    })
}
