/* eslint-disable no-param-reassign */

// It would be cool to remove this and change everything to vanilla JS
// However for some parts that is a non trivial undertaking and we should
// do it gradually.

// So this is a copy of jQuery with only the parts we still use.
// It can shrink and over time be removed.

export interface MiniQuery {
  on: (
    event: string,
    selectorOrHandler: string | EventListener,
    handler?: EventListener,
  ) => MiniQuery
  trigger: (event: string) => MiniQuery
  forEach: (callback: (el: Element, index: number) => void) => MiniQuery
  addClass: (className: string) => MiniQuery
  removeClass: (className: string) => MiniQuery
  toggleClass: (className: string) => MiniQuery
  attr: (name: string, value?: string) => MiniQuery | string | undefined
  data: (key: string, value?: string) => MiniQuery | string | undefined
  hide: () => MiniQuery
  show: () => MiniQuery
  text: (content?: string) => string | MiniQuery
  html: (content?: string) => MiniQuery | string
  append: (content: string | Element) => MiniQuery
  remove: () => MiniQuery
  closest: (selector: string) => MiniQuery
  find: (selector: string) => MiniQuery
  parent: () => MiniQuery
  parents: (selector: string) => MiniQuery
  is: (selector: string) => boolean
  each: (callback: (index: number, element: Element) => void) => MiniQuery
  get: () => Element[]
  first: () => MiniQuery
  last: () => MiniQuery
  prev: (selector?: string) => MiniQuery
  after: (content: string | Element | MiniQuery) => MiniQuery
  length: number
  hasClass: (className: string) => boolean
  height: () => number
  offset: () => { top: number; left: number }
  scrollTop: (value?: number) => void
  replace: (pattern: string | RegExp, replacement: string) => string
}

function $(
  selector: string | Element | Element[] | NodeList | Document | Window | EventTarget,
): MiniQuery {
  const elements: Element[] = (() => {
    if (typeof selector === 'string') {
      return Array.from(document.querySelectorAll(selector))
    }
    if (selector instanceof Element) {
      return [selector]
    }
    if (selector instanceof Document) {
      return [selector.documentElement]
    }
    if (selector instanceof NodeList || Array.isArray(selector)) {
      return Array.from(selector).filter((node): node is Element => node instanceof Element)
    }
    if (selector === window || selector instanceof EventTarget) {
      return []
    }
    return []
  })()

  const miniQuery: MiniQuery = {
    on: (event, selectorOrHandler, handler) => {
      if (typeof selectorOrHandler === 'string' && handler) {
        // Event delegation
        elements.forEach((el) => {
          el.addEventListener(event, (e: Event) => {
            if (e.target && (e.target as Element).matches(selectorOrHandler)) {
              handler.call(e.target, e)
            }
          })
        })
      } else if (typeof selectorOrHandler === 'function') {
        // Direct event binding
        elements.forEach((el) => el.addEventListener(event, selectorOrHandler))
      }
      return miniQuery
    },
    trigger: (event) => {
      elements.forEach((el) => el.dispatchEvent(new Event(event)))
      return miniQuery
    },
    forEach: (callback) => {
      elements.forEach((el, index) => callback(el, index))
      return miniQuery
    },
    addClass: (className) => {
      elements.forEach((el) => el.classList.add(className))
      return miniQuery
    },
    removeClass: (className) => {
      elements.forEach((el) => el.classList.remove(className))
      return miniQuery
    },
    toggleClass: (className) => {
      elements.forEach((el) => el.classList.toggle(className))
      return miniQuery
    },
    attr: (name, value) => {
      if (value === undefined) {
        return elements[0]?.getAttribute(name) ?? undefined
      }
      elements.forEach((el) => el.setAttribute(name, value))
      return miniQuery
    },
    data: (key, value) => {
      if (value === undefined) {
        const firstElement = elements[0]
        return firstElement instanceof HTMLElement ? firstElement.dataset[key] : undefined
      }
      elements.forEach((el) => {
        if (el instanceof HTMLElement) {
          el.dataset[key] = value
        }
      })
      return miniQuery
    },
    hide: () => {
      elements.forEach((el) => {
        if (el instanceof HTMLElement) {
          el.style.display = 'none'
        }
      })
      return miniQuery
    },
    show: () => {
      elements.forEach((el) => {
        if (el instanceof HTMLElement) {
          el.style.display = 'block'
        }
      })
      return miniQuery
    },
    text: (content) => {
      if (content === undefined) {
        return elements[0]?.textContent || ''
      }
      elements.forEach((el) => {
        el.textContent = content
      })
      return miniQuery
    },
    html: (content) => {
      if (content === undefined) {
        return elements[0]?.innerHTML || ''
      }
      elements.forEach((el) => {
        el.innerHTML = content
      })
      return miniQuery
    },
    append: (content) => {
      elements.forEach((el) => {
        if (content instanceof Element || Array.isArray(content)) {
          const contentArray = Array.isArray(content) ? content : [content]
          contentArray.forEach((contentElement) => el.appendChild(contentElement))
        } else {
          el.insertAdjacentHTML('beforeend', content as string)
        }
      })
      return miniQuery
    },
    remove: () => {
      elements.forEach((el) => el.remove())
      return miniQuery
    },
    closest: (selectorString) => $(elements[0]?.closest(selectorString) || []),
    find: (selectorString) => {
      const found = elements.flatMap((el) => Array.from(el.querySelectorAll(selectorString)))
      return $(found)
    },
    parent: () => {
      const parentElements = elements
        .map((el) => el.parentElement)
        .filter((el): el is HTMLElement => el !== null)
      return $(parentElements)
    },
    parents: (selectorString) => {
      const parents = new Set<Element>()
      elements.forEach((el) => {
        let parent = el.parentElement
        while (parent) {
          if (parent.matches(selectorString)) parents.add(parent)
          parent = parent.parentElement
        }
      })
      return $(Array.from(parents))
    },
    is: (selectorString) => elements[0]?.matches(selectorString) || false,
    each: (callback) => {
      elements.forEach((el, index) => callback(index, el))
      return miniQuery
    },
    get: () => elements,
    first: () => $(elements[0] || []),
    last: () => $(elements[elements.length - 1] || []),
    prev: (selector2) => {
      if (selector2) {
        return $(
          elements.flatMap((el) =>
            el.previousElementSibling?.matches(selector2) ? [el.previousElementSibling] : [],
          ),
        )
      }
      return $(
        Array.from(
          elements.flatMap((el) => (el.previousElementSibling ? [el.previousElementSibling] : [])),
        ),
      )
    },
    after: (content: string | Element | MiniQuery) => {
      if (typeof content === 'object' && 'get' in content) {
        const elements2 = content.get()
        elements2.forEach((el) => {
          if (el instanceof Element) {
            elements2.forEach((originalEl) => originalEl.after(el.cloneNode(true)))
          }
        })
      } else if (content instanceof Element) {
        elements.forEach((el) => el.after(content.cloneNode(true)))
      } else {
        elements.forEach((el) => el.insertAdjacentHTML('afterend', content))
      }
      return miniQuery
    },
    length: elements.length,
    hasClass: (className) => elements[0]?.classList.contains(className) || false,
    height: () => (elements[0] instanceof HTMLElement ? elements[0].offsetHeight : 0),
    offset: () => {
      const el = elements[0]
      return el instanceof HTMLElement
        ? { top: el.offsetTop, left: el.offsetLeft }
        : { top: 0, left: 0 }
    },
    scrollTop: (value?: number) => {
      if (value === undefined) {
        return elements[0] instanceof HTMLElement ? elements[0].scrollTop : 0
      }
      elements.forEach((el) => {
        if (el instanceof HTMLElement) el.scrollTop = value
      })
      return miniQuery
    },
    replace: (pattern, replacement) => {
      const text = elements[0]?.textContent || ''
      return text.replace(pattern, replacement)
    },
  }

  return miniQuery
}

export default $
