import ClipboardJS from 'clipboard'
import Cookies from 'js-cookie'
import Prism from 'prismjs'

// markup needs to go first, or JavaScript inside HTML will not be colored:
// https://github.com/PrismJS/prism/issues/1322
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-markup-templating'

import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-c' // can't use objectivec without loading c first
import 'prismjs/components/prism-coffeescript'
import 'prismjs/components/prism-dart'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-diff'
import 'prismjs/components/prism-erb'
import 'prismjs/components/prism-go'
import 'prismjs/components/prism-hcl'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-objectivec' // can't use objectivec without loading c first
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-python'
import 'prismjs/components/prism-ruby'
import 'prismjs/components/prism-rust'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-swift'
import 'prismjs/components/prism-yaml'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-toml'

import 'prismjs/plugins/keep-markup/prism-keep-markup'

import { terminologySchema } from ':/_types/schemas.ts'

import { Config } from '../../../config.ts'
import { bootIntercom } from '../sharedWithConsole/intercom-facade/boot-intercom.ts'
import $miniQuery from '../utils/miniQuery.ts'

const config = Config()

export const TiCommon = {
  initTooltips() {
    // This is bootstrap so we can't remove the $ just yet
    const selector =
      'a[data-toggle="tooltip"], div[data-toggle="tooltip"], img[data-toggle="tooltip"], span[data-toggle="tooltip"]'
    if (!$(selector).tooltip) {
      console.log('bootstrap tooltip not found on jQuery')
    } else {
      $(selector).tooltip({
        container: 'body',
      })
    }
  },

  enableSecrets() {
    const $a = $miniQuery(document.createElement('a'))
    if ($a) {
      // @ts-expect-error meh
      $a.attr('href', '#show')
        // @ts-expect-error meh
        .attr('text', 'display secret')
        .on('click', (e: Event) => {
          $miniQuery(e.currentTarget as Element)
            .hide()
            .prev('.secret')
            .show()
          return false
        })

      $miniQuery('.secret').hide().after($a)
    }
  },

  bindIntercomify() {
    // To explain, we always want customers to be able to reach us.
    // Something being broken and support links not working either, is the worst case scenario
    // So we want to be super defensive with this code and assume anything will break, and then
    // still let customers email us; so:
    // - our support links are like:
    //   <a class="js-intercomify" href="mailto:support@transloadit.com?subject=Hi%20there">Contact</a>
    // - then we progressively enhance to showing the Intercom bubble, and prevent the
    //   href from working. we also set it to # so you don't see mailto: in the statusbar
    // - but we restore the href on mouseout, so that should js or intercom break, the regular
    //   link can still work to open the email client
    // - if an error is thrown in our 'own' code so like by the Intercom or perhps URLSearchParams classes
    //   then we catch and use window.location.href to open the mailclient ourselves
    // - if there was a ?subject=..., that will be used as the opening message in intercom
    // - map this domain to 127.0.0.1 as intercom will not allow a 'fake' hostname like 'localhost':
    //   http://transloadit.dev:8111/

    const HREF_HOT = '#'
    $miniQuery('.js-intercomify').each((index, element) => {
      const $a1 = $miniQuery(element)
      $a1.attr('title', 'Open Intercom')
      const HREF_FALLBACK = $a1.attr('href')

      const setComLinkToFallback = (event: Event) => {
        const $a2 = $miniQuery(event.currentTarget as Element)
        if ($a2.attr('href') !== HREF_FALLBACK) {
          if (!HREF_FALLBACK) return
          $a2.attr('href', HREF_FALLBACK as string)
        }
      }

      const setComLinkToPopup = (event: Event) => {
        const $a2 = $miniQuery(event.currentTarget as Element)
        if ($a2.attr('href') !== HREF_HOT) {
          $a2.attr('href', HREF_HOT)
        }
      }

      $miniQuery(element).on('mouseenter', setComLinkToPopup)
      $miniQuery(element).on('focus', setComLinkToPopup)
      $miniQuery(element).on('mouseout', setComLinkToFallback)
      $miniQuery(element).on('blur', setComLinkToFallback)

      $miniQuery(element).on('click', async (event) => {
        event.preventDefault()

        try {
          const href = HREF_HOT
          const strParams = href.replace(/(.*)\?/, '')
          const urlParams = new URLSearchParams(strParams)
          const subject = urlParams.get('subject') ?? ''
          if (!window.Intercom) await bootIntercom()
          window.Intercom('showNewMessage', subject)
        } catch (showNewMessageError) {
          console.error(showNewMessageError)
          if (!HREF_FALLBACK) return
          window.location.href = HREF_FALLBACK as string
        }
      })
    })
  },

  bindClosePopoverOnBodyClick() {
    $miniQuery('body').on('click', (e) => {
      // only buttons
      if (!e.target) return

      if (
        $(e.target).data('toggle') !== 'popover' &&
        $(e.target).parents('.popover.in').length === 0
      ) {
        if (!$('[data-toggle="popover"]').popover) {
          console.log('bootstrap popover not found on jQuery')
        } else {
          $('[data-toggle="popover"]').popover('hide')
        }
      }
    })
  },

  bindJsFlashMassaging() {
    // Client-side js_flash_massaging
    const cookieVal = Cookies.get('js_flash_massaging')
    if (!cookieVal) {
      return
    }

    let messages = []
    try {
      messages = JSON.parse(cookieVal)
    } catch (e) {
      console.log(`Error while parsing: ${cookieVal}. ${e}`)
    }

    const sanitized = []
    let success: string | boolean = ''

    for (const message of messages) {
      if (!message.type || !message.text) {
        console.log(`Invalid js_flash_massaging message`, message)
        return
      }

      success = message.type === 'ok'

      sanitized.push(
        message.text.replace(/&/g, '&amp;').substr(0, 400),
        // Allow html for now.
        // .replace(/</g, '&lt;')
        // .replace(/"/g, '&quot;')
      )
    }

    let $container = null
    let html = ''
    if (($container = $miniQuery('.content .FormStatus').last()).is('*')) {
      const klass = success ? 'FormStatus--success' : 'FormStatus--error'
      html = sanitized.join('; ')
      $container.addClass(klass)
    } else if (($container = $miniQuery('.content div.messages').last()).is('*')) {
      const klass = success ? 'success' : 'danger'
      html = ''
      html += `<div class="alert alert-${klass}">`
      // html += '  <button type="button" class="close" data-dismiss="alert">×</button>'
      html += `  ${sanitized.join('; ')}`
      html += '</div>'
    } else {
      console.log(`Cannot find container to place: ${sanitized.join('; ')}`)
      return
    }

    $container.html(html)
    $container.removeClass('hidden')
    $container.show()

    console.log(`Erasing cookie 'js_flash_massaging'`)
    Cookies.remove('js_flash_massaging')
  },

  enableCopyButtons() {
    const clipboard = new ClipboardJS('.js-copy-code-btn', {
      target(trigger: Element): Element {
        // Ensure that the previous element is not null and is an Element
        if (trigger.previousElementSibling instanceof Element) {
          return trigger.previousElementSibling
        }

        // Handle the case where there is no previous element or it's not an Element
        throw new Error('Previous element is not available or is not an Element.')
      },
    })

    clipboard.on('success', (e) => {
      e.trigger.classList.add('copied')

      setTimeout(() => {
        e.trigger.classList.remove('copied')
      }, 1000)

      e.clearSelection()
    })

    clipboard.on('error', (e) => {
      console.error('Action:', e.action)
      console.error('Trigger:', e.trigger)
    })
  },

  async bindTerminology() {
    window.previousTerminologyLocations = {}

    const termUrl = `/datasets/terminology.json`

    const response = await fetch(termUrl)
    const unsafe = await response.json()
    const aliases: Record<string, string> = {}
    const terminology = terminologySchema.parse(unsafe)

    Object.entries(terminology).forEach((term) => {
      const [word, defs] = term

      defs.aliases.forEach((alias) => {
        aliases[alias] = word
      })
    })

    // Go over each <dfn>
    $miniQuery('dfn').each((index, element) => {
      // Look up description by curTerm term
      const myOffset = $miniQuery(element)?.offset()?.top ?? 0
      const curTerm = $miniQuery(element).text().replace(/\s+/g, ' ') // \s+ replaces newlines with spaces
      const term = terminology[curTerm] || terminology[aliases[curTerm]]

      if (!term) {
        console.log(`Unable to find: "${curTerm}" in terminology at ${termUrl}`)
        return
      }

      const { description } = term

      // Set tooltip
      if (window.previousTerminologyLocations) {
        window.previousTerminologyLocations[curTerm] = myOffset
      }

      $(element)
        .attr({
          'data-delay': '500',
          'data-trigger': 'click hover',
          'data-toggle': 'popover',
          'data-placement': 'bottom auto',
          'data-content': description,
        })
        .popover({ container: 'body' })

      $miniQuery(element)
        .closest('p,li,td,span')
        .on('mouseenter', () => {
          $miniQuery(element).addClass('with-tooltip')
        })
        .on('mouseleave', () => {
          $miniQuery(element).removeClass('with-tooltip')
        })

      // let body = `${description} <small>[<a href="/docs/#terminology">terminology</a>]</small>`
    })
  },

  navCollapsible() {
    if ($miniQuery('.nav .has-collapsible').length === 0) {
      return
    }

    const className = 'open'
    let timer: ReturnType<typeof window.setTimeout> | null = null

    if ($miniQuery('html').hasClass('touch')) {
      $miniQuery('.nav a.has-collapsible').on('click', (e) => {
        $miniQuery(e.currentTarget as Element)
          .parent()
          .toggleClass('open')
        return false
      })
    } else {
      $miniQuery('.nav a.has-collapsible')
        .parent()
        .on('mouseenter', (e) => {
          $miniQuery(e.currentTarget as Element).addClass(className)
          if (timer) {
            window.clearTimeout(timer)
          }
        })
        .on('mouseleave', (e) => {
          const self = e.currentTarget
          if (timer) {
            window.clearTimeout(timer)
          }
          timer = setTimeout(() => {
            $miniQuery(self as Element).removeClass(className)
          }, 3 * 1000)
        })
    }

    $miniQuery('body').on('click', (e) => {
      if (
        $miniQuery(e.target as Element).hasClass('collapsible') ||
        $miniQuery(e.target as Element).parents('.collapsible').length > 0
      ) {
        // inside the collapsible area
      } else {
        $miniQuery('nav ul > li').removeClass(className)
      }
    })
  },

  docsPermalinkHeaders() {
    const selectorPrefix = '.js-docs '

    const selector = selectorPrefix + ['h2', 'h3', 'h4'].join(`, ${selectorPrefix}`)
    $miniQuery(selector).each((index, element) => {
      const header = $miniQuery(element)

      if (header.parents('.modal').length > 0) {
        return
      }

      let id = header.attr('id')
      if (!id) {
        id = header
          .text()
          .replace(/[^a-z]/gi, '-')
          .replace(/(^-+|-+$)/g, '')
          .replace(/--+/g, '-')
          .toLowerCase()

        header.attr('id', id)
      }

      // Create a new anchor element using document.createElement
      const link = document.createElement('a')
      link.classList.add('docs-permalink')
      link.setAttribute('href', `#${id}`)
      link.setAttribute('aria-hidden', 'true')

      // Append the link to the header
      header.append(link)
    })
  },

  async makeTweetGrid() {
    const { default: MagicGrid } = await import('magic-grid')

    if (!MagicGrid) {
      console.log('No magic-grid plugin found')
    }

    const magicGrid = new MagicGrid({
      container: '.js-tweet-masonry',
      gutter: 20,
      static: true,
    })

    magicGrid.listen()
  },

  sidebarToggler() {
    $miniQuery('.sidebar-toggle:not(.togglefied)').on('click', (e) => {
      $miniQuery(e.currentTarget as Element).addClass('togglefied')
      $miniQuery('.sidebar').toggleClass('open')
      return false
    })
  },

  async domUpdated() {
    TiCommon.initTooltips()
    const highlight = async () => Prism.highlightAll()
    highlight()
  },

  createExpandablePreBlocks() {
    // expandable code blocks
    $miniQuery('div.expandable pre').each((index, element) => {
      const $this = $miniQuery(element)
      const maxHeight = $this.height()
      const $code = $this.find('code')
      const codeHeight = $code.height()

      if (codeHeight && maxHeight && codeHeight <= maxHeight) {
        return
      }

      const labels = {
        expand: 'Show more',
        collapse: 'Show less',
      }

      const $toggler = $miniQuery('<a></a>').html(
        `<span class="expandable__toggler-label">${labels.expand}</span> <span class="expandable__toggler-icon"><svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" fill="currentColor" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg></span>`,
      )
      if ($toggler instanceof Object && 'attr' in $toggler) {
        // @ts-expect-error meh
        $toggler
          .attr('href', '#')
          // @ts-expect-error meh
          .addClass('expand-toggler')
          .on('click', () => {
            $this.toggleClass('expand')
            if ($this.hasClass('expand')) {
              $toggler.find('.expandable__toggler-label').html(labels.collapse)
              $toggler.attr('data-expanded', '')
            } else {
              $toggler.find('.expandable__toggler-label').html(labels.expand)
              $toggler.attr('data-expanded', undefined)
            }
            return false
          })
      }

      $this.addClass('has-toggle').after($toggler)
    })
  },

  bindStickyIntegration() {
    function switchOver(options: { intId?: string; minislug?: string | null }) {
      let { intId, minislug } = options
      if (intId) {
        $miniQuery(`a.IconTabs-integration[href="${intId}"]`).each((index, element) => {
          minislug = $miniQuery(element).data('integration-minislug') as string | null
          $(element).tab('show')
        })
      }

      // Also switch over all other tabs to this language on this page (but not self, again)
      if (minislug) {
        if (!intId) {
          intId = '#non-existing-ever'
        }
        // minislug can be passed, or looked up via a passed intId and set higher up
        $miniQuery('a.IconTabs-integration').each((index, element) => {
          const $element = $miniQuery(element)
          if (
            $element.data('integration-minislug') === minislug &&
            $element.attr('href') !== intId
          ) {
            $(element).tab('show')
          }
        })
      }
    }

    switchOver({
      intId: window.location.hash,
      minislug: window.localStorage.getItem('stickyIntegration'),
    })

    $miniQuery('body').on('click', 'a.IconTabs-integration[data-toggle="tab"]', (event: Event) => {
      event.preventDefault()

      const target = event.target as HTMLElement
      const viewportOffset = target.getBoundingClientRect().top

      const intId = target.getAttribute('href')
      const minislug = $miniQuery(target).data('integration-minislug') as string | null
      if (window.history.pushState) {
        window.history.pushState(null, '', intId)
      } else {
        window.location.hash = intId || ''
      }
      if (minislug) {
        window.localStorage.setItem('stickyIntegration', minislug)
      }

      switchOver({ intId: intId as string | undefined })

      const offsetAfterClick = $miniQuery(target).offset()?.top ?? 0
      const newScrollTop = offsetAfterClick - viewportOffset
      $miniQuery(window).scrollTop(newScrollTop)

      return false
    })

    $miniQuery(window).on('popstate', () => {
      // The popstate event of the Window interface is fired when the active
      // history entry changes while the user navigates the session history.
      const firstTabIntegration = $miniQuery('a.IconTabs-integration[data-toggle="tab"]')
        .first()
        .attr('href')

      const intId =
        window.location.hash ||
        (typeof firstTabIntegration === 'string' ? firstTabIntegration : undefined)

      switchOver({ intId })
    })
  },

  bindNewsletterSubscribe() {
    async function handleSubmission(form: HTMLFormElement) {
      const button = form.querySelector('.js-newsletter-button')
      const input = form.querySelector('.js-newsletter-input')
      const okElement = form.querySelector('.js-newsletter-ok')
      const errorElement = form.querySelector('.js-newsletter-error')
      const formData = new FormData(form)

      if (!(button instanceof HTMLButtonElement)) return
      if (!(input instanceof HTMLInputElement)) return
      if (!(errorElement instanceof HTMLParagraphElement)) return
      if (!(okElement instanceof HTMLParagraphElement)) return

      const originalText = button.textContent

      button.disabled = true
      button.textContent = '…'
      input.disabled = true

      try {
        const headers = new Headers({
          accept: 'application/json',
        })
        const token = Cookies.get('csrfToken2') ?? Cookies.get('csrfToken')
        if (token) headers.set('X-CSRF-Token', token)

        const res = await fetch(`${config.webapiEndpoint}/subscribers/add.json`, {
          method: 'post',
          body: formData,
          headers,
        })

        const data = await res.json()
        if (!data) {
          console.error({ data })
          throw new Error('Empty response from server. ')
        } else if (!data.meta) {
          console.error({ data })
          throw new Error('Invalid response from server. ')
        } else if (data.meta.status !== 'ok') {
          console.error({ data })
          throw new Error(`Server returned an error. ${data.meta.message}`)
        }

        button.remove()
        input.remove()
        $miniQuery(errorElement).hide()
        $miniQuery(okElement).hide().text(data.meta.message)
        $miniQuery(okElement).show()
      } catch (err) {
        if (!(err instanceof Error)) {
          throw new Error(`Was thrown a non-Error ${err}`)
        }

        $miniQuery(okElement).hide()
        $miniQuery(errorElement).text(err.message)
        $miniQuery(errorElement).show()
        button.disabled = false
        input.disabled = false
        button.textContent = originalText
      }
    }

    for (const button of document.querySelectorAll('body.c-Blog .js-newsletter-button')) {
      const form = button.closest('form')
      if (!form) return
      form.addEventListener('submit', (event) => {
        event.preventDefault()
        handleSubmission(form)
      })
    }
  },
}
