import { ready } from '../sharedWithConsole/ready.ts'

ready(() => {
  const premiumPackages = document.querySelector('.c-PremiumPackages')
  if (premiumPackages) {
    // Show hidden intercom when user clicks button in application section
    document.getElementById('cta_application')?.addEventListener('click', () => {
      const intercomContainer = document.getElementById('intercom-container')
      if (intercomContainer instanceof HTMLElement) {
        intercomContainer.style.display = 'block'
      }
    })

    // Smooth scrolling for anchor links
    document
      .querySelectorAll<HTMLAnchorElement>('a[href^="#"]:not([href="#"])')
      .forEach((anchor) => {
        anchor.addEventListener('click', function (e) {
          e.preventDefault()
          const hash = this.getAttribute('href')
          if (!hash) return

          const target =
            document.querySelector(hash) || document.querySelector(`[name=${hash.slice(1)}]`)
          if (!(target instanceof HTMLElement)) return

          const scrollOptions: ScrollToOptions = {
            behavior: 'smooth',
            top: window.innerWidth > 768 ? target.offsetTop : target.offsetTop - 70,
          }

          window.scrollTo(scrollOptions)
        })
      })
  }
})
