import { captureException } from '@sentry/browser'

import afterLoginDataFetch from '{src}/javascripts/sharedWithConsole/afterLoginDataFetch/index.ts'
import { bootIntercom } from '{src}/javascripts/sharedWithConsole/intercom-facade/boot-intercom.ts'
import trackIntercomLoadState from '{src}/javascripts/sharedWithConsole/trackIntercomLoadState.ts'
import { trackPageview } from '~/features/analytics/plausible.ts'

import lazyLoadVideos from './lazyLoadVideos.ts'
import { TiCommon } from './ti-common.ts'
import sendTracker from '../sendTracker.ts'
import { initDocSearch } from '../sharedWithConsole/docsearch/init.ts'
import { ready } from '../sharedWithConsole/ready.ts'
import $miniQuery from '../utils/miniQuery.ts'

ready(async (): Promise<void> => {
  try {
    trackPageview()
  } catch (e) {
    captureException(e)
  }

  initDocSearch()
  trackIntercomLoadState()
  lazyLoadVideos()

  if (window.location.pathname === '/') {
    setTimeout(async () => {
      await bootIntercom(false)
    }, 5000)
  } else {
    await bootIntercom(false)
  }

  TiCommon.enableCopyButtons()

  afterLoginDataFetch().then(() => {
    sendTracker()
    TiCommon.bindIntercomify()
    TiCommon.bindNewsletterSubscribe()
  })

  TiCommon.enableSecrets()
  TiCommon.navCollapsible()
  TiCommon.docsPermalinkHeaders()
  TiCommon.sidebarToggler()
  TiCommon.bindJsFlashMassaging()
  TiCommon.bindClosePopoverOnBodyClick()
  TiCommon.bindStickyIntegration()
  TiCommon.bindTerminology().catch((e) => {
    captureException(e)
  })

  $miniQuery(document).on('ajax_loaded', TiCommon.domUpdated)
  $miniQuery(document).trigger('ajax_loaded')

  document.querySelector('.js_check_all')?.addEventListener('click', (e) => {
    e.preventDefault()
    document.querySelectorAll('table input[type=checkbox]').forEach((checkbox) => {
      if (checkbox instanceof HTMLInputElement) {
        // eslint-disable-next-line no-param-reassign
        checkbox.checked = true
      }
    })
  })

  document.querySelectorAll('.js-btn-form-submit').forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault()
      const form = (e.target as HTMLElement).closest('form')
      form?.submit()
    })
  })

  TiCommon.createExpandablePreBlocks()

  const tweetMasonry = document.querySelector('.js-tweet-masonry')
  if (tweetMasonry) {
    TiCommon.makeTweetGrid()
  }

  const highlightedDemos = document.querySelector('.js-highlightedDemos')
  if (highlightedDemos) {
    const moreDemosBtn = document.querySelector('.js-show-more-demos') as HTMLElement | null
    const demos = highlightedDemos.querySelectorAll('.HighlightedDemo')

    if (demos.length > 6 && moreDemosBtn) {
      let visibleDemos = 6
      moreDemosBtn.style.display = 'inline-block'

      const updateDemoVisibility = () => {
        demos.forEach((demo, index) => {
          const demoElement = demo as HTMLElement
          demoElement.style.display = index < visibleDemos ? 'flex' : 'none'
        })
        if (visibleDemos >= demos.length) {
          moreDemosBtn.style.display = 'none'
        }
      }

      updateDemoVisibility()

      moreDemosBtn.addEventListener('click', () => {
        visibleDemos += 3
        updateDemoVisibility()
      })
    }
  }

  if (document.querySelector('.table-of-contents')) {
    const { default: enableToCNavigation } = await import('./tocNavigationFeedback.ts')
    enableToCNavigation()
  }

  if (document.querySelectorAll('.plyr-player').length > 0) {
    const { default: mediaElementify } = await import('../sharedWithConsole/mediaelementify.ts')
    mediaElementify()
  }
})
