import { z } from 'zod'

export const responseCodeCollectionSchema = z
  .object({
    generated_response_codes_error: z.record(z.string()),
    generated_response_codes_ok: z.record(z.string()),
  })
  .strict()
export type ResponseCodeCollection = z.infer<typeof responseCodeCollectionSchema>

export const termSchema = z
  .object({
    description: z.string(),
    aliases: z.array(z.string()),
  })
  .strict()
export type Term = z.infer<typeof termSchema>

export const terminologySchema = z.record(termSchema)
export type Terminology = z.infer<typeof terminologySchema>

export const planSchema = z
  .object({
    slug: z.string(),
    name: z.string(),
    color: z.string(),
    uploading: z.string(),
    encoding: z.number(),
    virus_scanning: z.boolean(),
    ai_features: z.boolean(),
    distributed_video_transcoding: z.boolean(),
    overage: z.literal(false).or(z.string()),
    price_period: z.string(),
    gb_period: z.string(),
    price: z.string(),
    concurrent_jobs: z.number(),
    seats: z.number(),
    support: z.array(z.enum(['community forum', 'email', 'chat', 'phone', 'slack'])),
    included: z.number(),
  })
  .strict()
export type Plan = z.infer<typeof planSchema>

export const planCollectionSchema = z.record(planSchema)
export type PlanCollection = z.infer<typeof planCollectionSchema>

export const postSchema = z
  .object({
    layout: z.enum(['post', 'devtimes-post']),
    title: z.string(),
    tags: z.array(z.string()),
    last_modified: z.string().optional(),
    author: z.string(),
    coauthors: z.array(z.string()).optional(),
    meta_description: z.string(),
    permalink: z.union([z.string(), z.literal(false)]).optional(),
    excerpt: z.string().optional(),
    blurb: z.string().optional(),
    published: z.boolean().optional(),
    emojify: z.boolean().optional(),
    ai_improved_at: z.string().optional(),
    toc: z.boolean().optional(),
    items: z
      .array(
        z
          .object({
            raw: z.string(),
          })
          .or(
            z.object({
              url: z.string(),
              title: z.string(),
              image: z.string(),
              body: z.string(),
            }),
          ),
      )
      .optional(),
    categories: z.array(z.string()).optional(),
    posterboy_seo_idea: z
      .object({
        title: z.string(),
        summary: z.string(),
        outline: z.array(z.string()),
        keywords_to_use: z.array(z.string()),
        questions_to_use: z.array(z.string()),
        target_keyword: z.string(),
      })
      .optional(),
    ogimage: z.string().optional(),
    og_prompt: z.string().optional(),
    og_prompt_version: z.number().optional(),
    avatar: z.string().optional(),
    visible_title: z.string().optional(),
    position_filled: z.boolean().optional(),
    crosspost_to_medium: z.boolean().optional(),
    cover: z.string().optional(),
    cover2x: z.string().optional(),
    industry: z.string().optional(),
    image: z.string().optional(),
    hidden_comment: z.string().optional(),
    cover_photo: z.boolean().optional(),
    newsletter_online: z.string().optional(),
    cover_bgcolor: z.number().or(z.string()).optional(),
    keywords: z.array(z.string()).optional(),
    socials: z.array(z.string()).optional(),
    unsubscribe_link: z.string().optional(),
    redirect_from: z.string().or(z.array(z.string()).optional()),
    what_is_transloadit: z.boolean().optional(),
    cta: z.string().optional(),
  })
  .strict()

export const postFsSchema = postSchema.extend({
  __filepath: z.string(),
  __content: z.string(),
})

export const boastSchema = z.record(z.string().or(z.number()).or(z.array(z.string())))
export type BoastFacts = z.infer<typeof boastSchema>
