import cogwheelIcon from '{assets}/images/navbar_cogwheel.png'
import LoginDataInLS from '{src}/javascripts/sharedWithConsole/LoginDataInLS.ts'
import { apiFetchWorkspaceless } from '~/api/utils/apiFetch.ts'
import type { LoginData } from '~/features/workspaces/types.ts'

import activateIntercom from './utils/activateIntercom.ts'
import updateHeader from './utils/updateHeader.ts'

const _execute = ({
  loginData = null,
  isFinal = false,
}: {
  loginData?: LoginData | null
  isFinal: boolean
}) => {
  const hasLoginData =
    loginData && typeof loginData === 'object' && loginData !== null && loginData.email

  const loginDataOrDefault = (
    hasLoginData
      ? loginData
      : {
          loggedIn: false,
          email: 'unknown@example.com',
          name: 'unknown',
          avatarUrl: cogwheelIcon,
          cookieVal: 'a1cd12b4',
          plan_slug: 'none',
        }
  ) satisfies LoginData

  // Only active intercom once we know for use that we have the final correct data (asynchronously loaded),
  // so that we don't enable intercom when impersonating has begun https://github.com/transloadit/content/issues/1761
  if (isFinal) activateIntercom(loginDataOrDefault)
  updateHeader(loginDataOrDefault)
}

// Executes multiple functions that depend upon having loginData available:
// adds body classes, initiates intercom, etc.
const afterLoginDataFetch = async () => {
  // 1. In crm we can rely on jsonVars
  if (window.jsonVars?.login_data) {
    LoginDataInLS.set(window.jsonVars.login_data)
    _execute({ loginData: window.jsonVars.login_data, isFinal: true })
    return
  }

  // 2. Otherwise we use localStorage in order to quickly updateBodyClasses()
  const loginDataFromLS = LoginDataInLS.get({ checkCookie: true })
  _execute({ loginData: loginDataFromLS || null, isFinal: false })

  // 3. And proceed to fetch a more valid information from the api
  try {
    const res = await apiFetchWorkspaceless('/users/login_data.json', { method: 'GET' })
    if (res.meta.status === 'ok' && res?.data?.login_data) {
      const loginData: LoginData = res.data.login_data
      if (loginData.loggedIn) {
        LoginDataInLS.set(loginData)
        _execute({ loginData, isFinal: true })
      }
    } else {
      _execute({ loginData: null, isFinal: true })
    }
  } catch {
    _execute({ loginData: null, isFinal: true })
  }
}

export default afterLoginDataFetch
