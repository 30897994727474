import Cookies from 'js-cookie'

import type { LoginData } from '~/features/workspaces/types'

const get = (options: { checkCookie?: boolean; checkAvatar?: boolean }): false | LoginData => {
  const loginDataStr = localStorage.getItem('login_data')

  if (!loginDataStr) {
    return false
  }

  let loginData: LoginData
  try {
    loginData = JSON.parse(loginDataStr)
  } catch (e) {
    return false
  }

  if (!loginData.loggedIn) {
    return false
  }

  if (options?.checkCookie) {
    const cookieVal = Cookies.get('LoginStatus')
    if (loginData.cookieVal !== cookieVal) {
      return false
    }
  }

  if (options?.checkAvatar) {
    const hasProperAvatarUrl = loginData.avatarUrl.indexOf('https://secure.gravatar.com') !== -1
    if (!hasProperAvatarUrl) {
      return false
    }
  }

  return loginData
}

const set = (loginData: LoginData) => {
  if (loginData && loginData.loggedIn) {
    loginData.cookieVal = Cookies.get('LoginStatus')
    localStorage.setItem('login_data', JSON.stringify(loginData))
  } else {
    localStorage.removeItem('login_data')
  }
}

export default { get, set }
