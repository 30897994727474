import { ready } from '../sharedWithConsole/ready.ts'

ready(() => {
  const highlightElements = document.querySelectorAll<HTMLElement>('.js-urltransform-highlight')

  if (highlightElements.length) {
    // Highlight related objects in the demo
    highlightElements.forEach((element) => {
      element.addEventListener('mouseenter', () => {
        const currentItem = element.dataset.highlight
        document
          .querySelectorAll<HTMLElement>(`[data-highlight='${currentItem}']`)
          .forEach((el) => el.classList.add('urltransform-highlighted'))
      })

      element.addEventListener('mouseleave', () => {
        document
          .querySelectorAll<HTMLElement>('.js-urltransform-highlight')
          .forEach((el) => el.classList.remove('urltransform-highlighted'))
      })
    })

    // Precise alignment of the annotation pointers
    const alignAnnotation = (element: HTMLElement) => {
      const targetObject = document.querySelector<HTMLElement>(`.js-code-${element.dataset.object}`)
      if (targetObject) {
        const position = targetObject.offsetTop
        const offset = 3
        // eslint-disable-next-line no-param-reassign
        element.style.top = `${position + offset}px`
      }
    }

    document
      .querySelectorAll<HTMLElement>('.js-file-hint, .js-parameters-hint, .js-served-hint')
      .forEach(alignAnnotation)
  }
})
