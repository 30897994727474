import { ready } from '../sharedWithConsole/ready.ts'

type Mode = 'visible' | 'above' | 'below'

function checkVisible(elm: HTMLElement, argThreshold: number, argMode?: Mode) {
  // https://stackoverflow.com/a/5354536/151666
  const threshold = argThreshold || 0
  const mode = argMode || 'visible'

  const rect = elm.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  const above = rect.bottom - threshold < 0
  const below = rect.top - viewHeight + threshold >= 0

  if (mode === 'above') {
    return above
  }
  if (mode === 'below') {
    return below
  }
  return !above && !below
}

function setHidden(element: HTMLElement, hidden: boolean) {
  if (hidden) {
    element.setAttribute('hidden', '')
    element.style.display = 'none'
  } else {
    element.removeAttribute('hidden')
    element.style.display = 'block'
  }
}

const runOnStart = () => {
  const activeSidebarLink: HTMLElement | null = document.querySelector('.docs-nav__link--active')

  if (activeSidebarLink) {
    const topPos = activeSidebarLink.offsetTop
    const sidebar: HTMLElement | null = document.querySelector('.js-sidebar-scrolling-container')

    if (!checkVisible(activeSidebarLink, 30) && sidebar) {
      sidebar.scrollTop = topPos - 50
    }
  }

  if (document.querySelector('.js-comparer')) {
    const compareVersionsEl: HTMLElement | null = document.querySelector('.js-compare-versions')
    if (!compareVersionsEl) return
    const compareVersionsElTargetString = compareVersionsEl.dataset.target
    if (!compareVersionsElTargetString) return

    const target = document.querySelector(compareVersionsElTargetString)
    if (!target) return

    compareVersionsEl.addEventListener('click', () => {
      target.classList.toggle('compare-enabled')
    })

    const showDifferencesEl: HTMLElement | null = document.querySelector(
      '.js-only-show-differences',
    )
    if (!showDifferencesEl) return

    const showDifferencesTargetString = showDifferencesEl.dataset.target
    if (!showDifferencesTargetString) return

    showDifferencesEl.addEventListener('click', () => {
      const showDifferencesTarget = document.querySelector(showDifferencesTargetString)
      if (!showDifferencesTarget) return

      for (const tr of showDifferencesTarget.querySelectorAll('tr.homogeneous-yes')) {
        tr.classList.toggle('hidden')
      }
      target.classList.toggle('diff-enabled')
    })
  }

  const mql = window.matchMedia('(max-width: 767px)')
  const isSmallScreen = mql.matches

  const sidebar: HTMLElement | null = document.querySelector('.js-docs-sidebar')

  if (!sidebar || !isSmallScreen) return

  const toggleButtons: NodeListOf<HTMLElement> = document.querySelectorAll('.js-toggle-docs-menu')

  toggleButtons.forEach((toggleButton) => {
    const { slug } = toggleButton.dataset
    toggleButton.addEventListener('click', (event) => {
      event.preventDefault()

      const subMenus: NodeListOf<HTMLElement> | null =
        sidebar.querySelectorAll('.js-docs-sidebar-list')
      const subMenuTarget = sidebar.querySelector(`.js-docs-sidebar-list--${slug}`)
      const shouldOpen = subMenuTarget ? subMenuTarget.hasAttribute('hidden') : false

      for (const subMenu of subMenus) {
        setHidden(subMenu, !(subMenuTarget === subMenu && shouldOpen))
      }

      setHidden(sidebar, !shouldOpen)
    })
  })

  const gStartedExtraLinksToggle = document.querySelector(
    '.js-toggle-more-docs-gstarted-links',
  ) as HTMLButtonElement

  const gStartedExtraLinksContainer = document.querySelector(
    '.js-gstarted-extra-links',
  ) as HTMLElement

  if (gStartedExtraLinksToggle && gStartedExtraLinksContainer) {
    gStartedExtraLinksToggle.addEventListener('click', () => {
      const isCurrentlyHidden = gStartedExtraLinksContainer.hasAttribute('hidden')
      setHidden(gStartedExtraLinksContainer, !isCurrentlyHidden)

      gStartedExtraLinksToggle.textContent = isCurrentlyHidden
        ? 'Show less sections'
        : 'Show more sections'
    })
  } else {
    console.error('Elements not found in the DOM')
  }
}

ready(() => {
  runOnStart()
})
