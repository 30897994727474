const loadDemoAndFocus = (
  initiatorElement: string,
  loadDemo: () => Promise<void>,
  focusElement: string,
) => {
  const tryButton = document.querySelector(initiatorElement)

  const focusElementFunction = async () => {
    const checkButtonInterval = setInterval(() => {
      const element: HTMLElement | null = document.querySelector(focusElement)
      if (element) {
        element.focus()
        clearInterval(checkButtonInterval)
      }
    }, 100)

    setTimeout(() => {
      clearInterval(checkButtonInterval)
    }, 5000)
  }

  function onHover() {
    loadDemo().catch((loadDemoError) => {
      console.error(loadDemoError)
    })
    if (!tryButton) return

    tryButton.removeEventListener('mouseover', onHover)
    tryButton.removeEventListener('focus', onHover)
  }

  if (tryButton) {
    tryButton.addEventListener('mouseover', onHover)
    tryButton.addEventListener('focus', onHover)
    tryButton.addEventListener('click', focusElementFunction)
  } else {
    // Fallback for when the trigger button is not present
    loadDemo().catch((loadDemoError) => {
      console.error(loadDemoError)
    })
  }
}

export default loadDemoAndFocus
