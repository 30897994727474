import jq from 'jquery'

if (!window.$) {
  window.$ = jq
}

if (!window.jQuery) {
  window.jQuery = window.$
}

export default window.$
