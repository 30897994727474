import { Config } from '../../config.ts'

const config = Config()

async function sendTracker() {
  const qs = new URL(window.location.href).searchParams

  const postData = new URLSearchParams()
  postData.set('via_referer', document.referrer)
  if (qs.has('utm_source')) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    postData.set('via_utm_source', qs.get('utm_source'))
  }
  if (qs.has('via_utm_medium')) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    postData.set('via_utm_medium', qs.get('utm_medium'))
  }
  if (qs.has('via_utm_campaign')) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    postData.set('via_utm_campaign', qs.get('utm_campaign'))
  }
  if (qs.has('via_utm_term')) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    postData.set('via_utm_term', qs.get('utm_term'))
  }
  if (qs.has('via_utm_content')) {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    postData.set('via_utm_content', qs.get('utm_content'))
  }

  const res = await fetch(`${config.webapiEndpoint}/tracker/add.json`, {
    method: 'post',
    mode: 'cors',
    credentials: 'include',
    body: postData,
    headers: {
      accept: 'application/json',
      // The tracker is purely to save referer in the user session,
      // which only works on the first page load, and CSRF requires a roundtrip
      // first so we explicitly don't send a CSRF token here.
      // On the CakePHP side, we have added a CSRF_SKIP for this.
    },
  })

  if (!res.ok) {
    const text = await res.text()
    console.error(`Tracker error: ${text}`)
    return null
  }

  const { data } = await res.json()
  const referer = data.trafficSource.via_referer || undefined
  if (!referer) {
    document.body.classList.add('browser-hides-referer')
  }

  return referer
}

export default sendTracker
