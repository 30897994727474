import './sharedWithConsole/request-idle-callback-polyfill.ts'

/* eslint-disable import/first */
if (typeof window.ResizeObserver === 'undefined') {
  const { ResizeObserver: Polyfill } = await import('@juggle/resize-observer')
  window.ResizeObserver = Polyfill
}

// Error reporting
import './main/sentry.ts'

import '../stylesheets/bootstrap.scss'
import '../stylesheets/app.scss'

import './global-jquery.ts'

import 'bootstrap-sass/assets/javascripts/bootstrap'

// Custom code
import './main/blog.ts'
import './main/docs.ts'
import './main/main.ts'
import './main/navigation.ts'
import './main/premium-package.ts'
import './main/ti-common.ts'
import './main/urltransform-demo.ts'

import './urltransform-demo.ts'
import './website-preview-demo.ts'
import './file-preview-demo.ts'
