import type TMediaElementify from '../sharedWithConsole/mediaelementify.ts'

// give the video elements `<video data-lazyloadsource="src.mp4" ...` ,
// and it's child `<source src=""...` attribute and this will take care of the rest
const lazyLoadVideos = async () => {
  let mediaElementify: typeof TMediaElementify | undefined

  if (document.querySelectorAll('.plyr-player').length > 0) {
    ;({ default: mediaElementify } = await import('../sharedWithConsole/mediaelementify.ts'))
    mediaElementify()
  }

  const lazyVideos = Array.from(document.querySelectorAll('video[data-lazyloadsource]'))

  const lazyVideoObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target instanceof HTMLVideoElement) {
          const video = entry.target
          const sourceElements = Array.from(video.querySelectorAll('source'))

          sourceElements.forEach((sourceElement) => {
            if (sourceElement instanceof HTMLSourceElement) {
              const src = video.getAttribute('data-lazyloadsource') || ''
              sourceElement.setAttribute('src', src)
            }
          })

          video.load()
          video.removeAttribute('data-lazyloadsource')
          lazyVideoObserver.unobserve(video)

          if (video.classList.contains('plyr-player')) {
            mediaElementify?.(entry.target)
          }
        }
      }
    })
  })

  lazyVideos.forEach((lazyVideo) => {
    lazyVideoObserver.observe(lazyVideo)
  })
}

export default lazyLoadVideos
