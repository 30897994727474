import '../stylesheets/urltransform-demo.scss'
import loadDemoAndFocus from '{src}/javascripts/main/loadDemoAndFocus.ts'

const urlTransformDemoArea = document.getElementById('urltransform-demo')

async function loadDemo() {
  if (urlTransformDemoArea !== null) {
    import('./urltransform-demo/render.tsx')
      .then(({ default: render }) => {
        render(urlTransformDemoArea)
      })
      .catch((urlTransformError) => {
        console.error(urlTransformError)

        if (typeof urlTransformError === 'string') {
          urlTransformDemoArea.innerHTML = urlTransformError
        } else if (urlTransformError instanceof Error) {
          urlTransformDemoArea.innerHTML = urlTransformError?.stack ?? urlTransformError.message
        }
      })
  }
}

loadDemoAndFocus('a[href="#smart-cdn"]', loadDemo, '.tl-range')
